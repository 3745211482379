<template>
  <div v-if="isPermission">
    <div class="mt-2">
      <CAlert color="success" v-if="isSuccess" >
        {{ $t('createProductSuccess') }}
      </CAlert>

      <div>
        <div class="row">
          <div class="col-md-10 col-sm-9 col-9">
            <h5 class="font-weight-normal">
              <span style="font-size: large;" :id="'productSerialName' + SKUName">
                {{ $t('productName') }}: {{ SKUName }}
              </span>
            </h5>
          </div>
          <div class="col-md-2 col-sm-3 col-3 text-right h5">
            <span class="font-weight-normal text-right h5" id="productSerialQuantity">
              {{ $t('quantity') }} 
            </span>
            ({{ countSeriallist }})
          </div>
        </div>
      </div>

      <hr />

      <h5 class="font-weight-normal" id="addSerialSection">{{ $t('addSerial') }}</h5>
      <CRow class="mb-2">
        <CCol lg="3" md="4" col="12" class="mb-2">
          <CButton block color="info" @click="generate" v-if="isEditData" id="createSerialButton">
            {{ $t('createSerial') }}
          </CButton>
          <CButton block color="success" disabled v-else id="createSerialButtonDisabled">
            {{ $t('createSerial') }}
          </CButton>
        </CCol>
        <CCol md="6" lg="7" sm="10" col="9">
          <CInput
            v-model.trim="SerialNumber"
            placeholder="ใส่หมายเลข Serial"
            id="serialNumberInput"
          />
          <CAlert color="danger" :show.sync="isError" id="serialNumberError">
            {{ titleError }}
          </CAlert>
        </CCol>
        <CCol md="2" lg="2" sm="2" col="3" class="mb-2">
          <CButton block color="success" @click="confirmAdd" v-if="isEditData" id="addSerialButton">
            {{ $t('add') }}
          </CButton>
          <CButton block color="success" disabled v-else id="addSerialButtonDisabled">
            {{ $t('add') }}
          </CButton>
        </CCol>
      </CRow>

      <CRow v-if="loadingButton == true">
        <CCol lg="3" md="4" sm="4" col="6"
          v-for="(item, index) in SerialList"
          :key="index"
          :id="'productSerial' + index" hover
        >
          <CCard :id="'productSerialCard' + index" style="box-shadow: 0 0 0 1px #e5e5e5;">
            <CCardBody class="srhover">
              <CRow class="justify-content-between">
                <CCol>
                  <p class="mb-0" style="font-size: smaller; color: dimgray;" :id="'productSerialLabel' + item.serialNumber">
                    {{ $t('productSN') }}
                  </p>
                  <h5 :id="'productSerialNumber' +index">
                    {{ item.serialNumber }}
                  </h5>
                </CCol>
                <CCol lg="2" col="2">
                  <img
                    src="/img/trash.png"
                    v-if="isEditData"
                    style="height: 18px; width: 18px; margin-right: 20px; cursor: pointer"
                    :id="'productSerialDelete' + index"
                    @click="confirmRemove(item, index)"
                  />
                </CCol>
              </CRow>
              <CBadge
                :id="'productSerialStatus' + item.serialNumber"
                :color="item.serialStatus === 'P1' ? 'success' : 'danger'"
              >
                <span>{{ item.serialStatus === 'P1' ? $t('available') : $t('soldOut') }}</span>
              </CBadge>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <div v-else class="text-center" width="100%">
        <button class="btn" type="button" disabled style="color: darkcyan;">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t('loading') }}
        </button>
      </div>
    </div>

    <modal
      color="success"
      :show.sync="confirmModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="addSerial"
      centered
      :alertError="alertError"
      :loading="loading"
      id="confirmSaveModal"
    >
      <div v-if="alertError">
        <h4 class="text-center text-danger">{{ textError }}</h4>
        <h5 class="mt-4 text-center text-danger">{{ textMessage }}</h5>
      </div>
      <div v-else>
        <h4 class="mt-4 mb-4 text-center">{{ $t('confirmSaveSerial') }}</h4>
      </div>
    </modal>

    <CRow>
      <modal
        color="danger"
        :show.sync="deleteModal"
        :title="$t('confirmDeleteProduct')"
        v-on:isSave="removeSerial"
        :loading="loading"
        centered
        id="confirmDeleteModal"
      >
        <h4 class="mt-4 mb-4 text-center">{{ $t('confirmDelete') }}</h4>
      </modal>
    </CRow>
  </div>
  <div v-else>
    <access-data id="accessDataComponent"></access-data>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  data() {
    return {
      isSuccess: false,
      SKUName: '',
      keyword: '',
      data: [],
      dataSKUbytype: [],
      itemObjectId: [],
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      addlist: [],
      Serial: [],
      SerialList: [],
      countSeriallist: 0,
      quantity: 1,
      countAddList: 0,
      SerialNumber: '',
      loadingButton: true,
      confirmModal: false,
      loading: true,
      title: '',
      deleteModal: false,
      itemDelete: {},
      isError: 0,
      titleError: '',
      alertError: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      return permis.findPermissionEdit('product', path)
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    fields() {
      return [{ key: 'name', label: this.$i18n.t('productName') }]
    },
  },
  created() {
    this.getSerial()
    this.getProduct()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    getSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      pos({
        url: '/api/v1.0/' + uid + '/productsku/getserial',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {

        this.SerialList = res.data.data
        this.countSeriallist = this.SerialList.length
      })
    },
    generate() {
      this.SerialNumber = util.generateSerial()
    },
    confirmAdd() {
      if (this.SerialNumber == '') {
        this.isError = 5
        this.titleError = this.$i18n.t('enterSerial')
      } else {
        this.title = this.$i18n.t('confirmSaveSerial')
        this.confirmModal = true
      }
    },
    confirmRemove(item) {
      this.itemDelete = item
      this.deleteModal = true
    },
    removeSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.itemDelete.objectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = false
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductSerial/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.loading = true
            this.deleteModal = false
            this.getSerial()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    addSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let dataitem = []
      let itemObject = this.$route.params.productSKUObjectId
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = false
      dataitem.push(this.SerialNumber)
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/addserial',
        data: {
          objectId: itemObject,
          SN: dataitem,
        },
        headers: headers,
      })
        .then(() => {
          setTimeout(() => {
            this.loading = true
            this.confirmModal = false
            this.getSerial()
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getProduct() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      let data = {}
      pos({
        url:
          '/api/v1.0/' +
          uid +
          '/ProductSKU/data/' +
          this.$route.params.productSKUObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data.document
        data = this.data
        this.SKUName = data.SKUName
        this.loadingButton = true
      })
    },
    addProductBOM() {
      this.$router.push('add/' + this.$route.params.productSKUObjectId)
    },
    goback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId
      this.$router.push({
        name: 'ProductDetail',
        params: { productSKUObjectId },
        query: {
          sort: this.$route.query.sort,
          page: this.$route.query.page,
          view: this.$route.view,
        },
      })
    },
  },
}
</script>

<style>


@media(min-height: 400px) {
  .body-bom {
    max-height: 320px
  }

  .cardadd {
    min-height: 400px;
    max-height: 400px;
    /* border-colo #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media(min-height: 600px) {
  .body-bom {
    max-height: 380px
  }

  .cardadd {
    min-height: 500px;
    max-height: 500px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media(min-height: 700px) {
  .body-bom {
    max-height: 400px
  }

  .cardadd {
    min-height: 520px;
    max-height: 520px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}

@media(min-height: 900px) {
  .body-bom {
    max-height: 520px
  }

  .cardadd {
    min-height: 640px;
    max-height: 640px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
.cursor {
  cursor: pointer;
}

.srhover:hover {
    -webkit-box-shadow: 0 12px 40px 0 rgb(0 0 0 / 16%);
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
}
</style>
